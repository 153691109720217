<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title>
        证书物流
      </template>
    </HeaderBox>

    <div class="list-box">
      <div class="certificate-list">
        <div class="certificate-item" v-for="item in certificateList" :key="item.tempId" @click="onTab(item)">
          <!-- <span>阿斯顿撒打算的</span> -->
          <span :class="{'hover-span' : tempId == item.tempId}">{{item.certifName}}</span>
        </div>
      </div>
      <div class="table-box">
        <h4 class="course-name">关联课程：{{courseName}}</h4>
        <div class="flex-div">
          <a-input class="input" v-model="queryParams.name" placeholder="请输入姓名" allowClear />
          <a-input class="input" v-model="queryParams.mobile" placeholder="请输入手机号" allowClear />
          <a-range-picker 
            class="timeInput" 
            @change="onSelectTime"
            :placeholder="['开始日期', '结束日期']"
            format="YYYY-MM-DD" />
          <a-range-picker 
            class="timeInput" 
            @change="onSelectExamTime"
            :show-time="{ format: 'HH:mm:ss' }"
            :placeholder="['考试开始日期', '考试结束日期']"
            format="YYYY-MM-DD HH:mm:ss" />
          <!-- <a-input class="input" placeholder="请输入考试场次" /> -->
          <!-- <a-input class="input" placeholder="发货状态" /> -->
          <a-select class="select" v-model="queryParams.logisticsStatus" placeholder="请选择发货状态" allowClear>
            <a-icon slot="suffixIcon" type="caret-down" />
            <a-select-option :value="0">未发货</a-select-option>
            <a-select-option :value="1">已发货</a-select-option>
          </a-select>
          <a-button type="primary" class="btn" @click="search()" v-hasPermi="['certif:logistics:list']">搜索</a-button>
        </div>
        <div class="flex-div">
          <!-- <a-button  class="btn" >下载用户模板</a-button> -->
          <a-button  class="btn" @click="downExcleData()">下载用户模板</a-button>
          <a-upload
            name="videoFileId"
            :beforeUpload="beforeUpload"
            :showUploadList="false"
            :customRequest="customRequest"
            :loading="loading"
          >
            <a-button v-hasPermi="['certif:logistics:upload']">导入证书信息</a-button>
          </a-upload>
          <!-- <a-button  class="btn" >导入证书信息</a-button> -->
        </div>
        <a-table
          class="table-template"
          :rowKey="item=>item.dataId"
          :columns="columns"
          :loading="loading"
          :data-source="tableData"
          @change="onPage"
          :pagination="{
            total:total,
            current:queryParams.pageNum,  
            defaultPageSize:queryParams.pageSize, 
            showSizeChanger: true,
            showTotal: function(total, range){
            return `共${total}条`}} ">
          <template slot="index" slot-scope="item, row, i">
            <div style="text-align: center;">
              {{ (queryParams.pageNum - 1) * queryParams.pageSize + i + 1 }}
            </div>
          </template>
          <template slot="logisticsStatus" slot-scope="item">
              <span>{{item == 1 ? '已发货' :'未发货'}}</span>
            </template>

          <template slot="operation" slot-scope="item">
            <div class="btn_router_link">
              <a @click="deleteData(item.dataId)" v-hasPermi="['certif:data:nullify']" v-show="item.logisticsStatus == 0">证书作废 | </a>
              <a @click="uploadLogi(item)" v-hasPermi="['certif:logistics:upload']">上传物流</a>
            </div>
          </template>
        </a-table>
      </div>
    </div>

    <!-- 编辑弹窗 -->
    <a-modal
      v-model="EditVisible"
      title="物流信息"
      okText="确认"
      width="550px"
      @ok="edititem()"
    >
      <div class="all_content_box">
        <div class="line-item">
          <span class="all_left_name">快递公司：</span>
          <a-input
            v-model="logiObj.logisticsCompany"
            class="right"
            placeholder="请输入快递公司"
          />
        </div>
        <div class="line-item">
          <span class="all_left_name">运单号：</span>
          <a-input
            v-model="logiObj.logisticsCode"
            class="right"
            placeholder="请输入运单号"
          />
        </div>
      </div>
    </a-modal>

    <!-- 数据弹窗 -->
    <LeadingIn
      :columns="columnsss"
      :successList="successList"
      :failureList="failureList"
      :visible="importVisible"
      :handleCancel="modelhandleCancel"
      :isShowErrorDownBtn = 1
    />
  </div>
</template>

<script>
import LeadingIn from "@/components/leadingIn.vue"; // 导入组件
import HeaderBox from '@/components/HeaderBox.vue'
const columns = [
  {
    title: "序号",
    width: "80px",
    align:'center',
    dataIndex: "index",
    scopedSlots: { customRender: 'index' }
  },
  {
    title: "姓名",
    align:'center',
    dataIndex: "name"
  },
  {
    title: "手机号",
    align:'center',
    dataIndex: "mobile"
  },
  {
    title: "证书编号",
    align:'center',
    dataIndex: "code"
  },
  {
    title: "发证时间",
    align:'center',
    dataIndex: "getcertifTime"
  },
  {
    title: "发货状态",
    align:'center',
    dataIndex: "logisticsStatus",
    scopedSlots: { customRender: "logisticsStatus" }
  },
  {
    title: "物流公司",
    align:'center',
    dataIndex: "logisticsCompany"
  },
  {
    title: "物流单号",
    align:'center',
    dataIndex: "logisticsCode"
  },
  {
    title: "操作",
    align:'center',
    width: "160px",
    scopedSlots: { customRender: "operation" }
  }
];
const columnsss = [
  {
    title: "考生姓名",
    align: "center",
    dataIndex: "name",
    width: 100,
  },
  {
    title: "考生手机号",
    align: "center",
    dataIndex: "mobile",
    width: 100,
  },
  {
    title: "证书编号",
    align: "center",
    dataIndex: "consigneeMobile",
    width: 100,
  },
  {
    title: "物流公司",
    align: "center",
    dataIndex: "logisticsCompany",
    width: 100,
  },
  {
    title: "物流单号",
    align: "center",
    dataIndex: "logisticsCode",
    width: 100,
  },
];
export default {
  // 可用组件的哈希表
  components: {HeaderBox, LeadingIn},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      loading:false,
      columns, // 表头
      tableData:[], // 列表数据
      certificateList: [],
      queryParams:{
        tempId:0, // 模版id
        name:'', // 姓名
        mobile:'', // 手机号
        startTime:'', // 开始时间
        endTime:'', // 结束时间
        startDate:'', // 考试开始时间
        endDate:'', // 考试结束时间
        // timeIds:'', // 场次id
        logisticsStatus: undefined, // 发货状态
        pageNum: 1,
        pageSize: 10,
      },
      total: 0, // 总数量
      selectedSignUpRowKeys: [],
      tempId:0, // 模板id
      courseId:0, // 课程id
      courseName: '', // 课程名称
      EditVisible: false, // 关联上传物流弹窗
      logiObj:{
        logisticsCode:"",
        logisticsCompany:"",
        dataId:"", // 证书数据id
      },
      // 弹框
      columnsss,
      importVisible: false, // 导入弹框
      successList: null, // 正确数据
      failureList: null, // 错误数据
    }
  },
  // 事件处理器
  methods: {
    // 分页
    onPage(e){
      this.queryParams.pageNum = e.current
      this.queryParams.pageSize = e.pageSize
      this.getDataList()
    },

    onTab(e){
      this.tempId = e.tempId
      this.courseId = e.courseId
      this.courseName = e.courseName
      this.queryParams.tempId = e.tempId
      this.queryParams.pageNum = 1
      this.getDataList()
    },
    // 查询证书配置列表
    getCertificateList(){
      this.loading = true
      this.$ajax({
        method: 'get',
        url:"/hxclass-management/certiftemp/getManualGenerationConfig",
      }).then(res => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.certificateList = res.data
          if(res.data.length){
            this.tempId = res.data[0].tempId
            this.courseName = res.data[0].courseName
            this.queryParams.tempId = res.data[0].tempId
            this.queryParams.courseId = res.data[0].courseId
            this.getDataList()
          }
        }
      }).catch(error => {
        this.loading = false
        if (error) {
          this.$message.warning('系统错误');
        }
      })
    },
    // 查询证书列表
    getDataList(){
      this.loading = true
      this.$ajax({
        method: 'get',
        url:"/hxclass-management/certifData/getCertifLogisticsList",
        params: this.queryParams
      }).then(res => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.tableData = res.data.records
          this.total = res.data.total
        } else {
          this.tableData = []
        }
      }).catch(error => {
        this.loading = false
        if (error) {
          this.$message.warning('系统错误');
        }
      })
    },

    // 试题附件上传前钩子
    beforeUpload(file) {
      var reg = /\.(xlsx|xls)(\?.*)?$/;
      if (!reg.test(file.name)) {
        this.$message.warning("仅支持：xlsx、xls格式文件");
        return false;
      }

      if (this.loading) {
        this.$message.warning("附件正在上传中，请稍后重试");
        return false;
      }
    },

    // 附件上传 - 单文件
    customRequest(fileData) {
      this.loading = true
      this.$ajax({
        method: 'POST',
        url:"/hxclass-management/certifData/excelImport",
        headers: [{ type: "file" }],
        params: {
          file: fileData.file,
          name: "file",
        },
      }).then(res => {
        this.loading = false
        this.getDataList()
        this.successList = res.data.ok;
        this.failureList = res.data.err;
        this.importVisible = true;
      }).catch(error => {
        this.loading = false
        if (error) {
          this.$message.warning('系统错误');
        }
      })
    },
    // 导入正确数据
    importSuccessList() {
      // let arr = [];
      // this.successList.forEach((element) => {
      //   arr.push({
      //     subId: element.subId,
      //     shipStatus: 1,
      //     logisticsCompany: element.logisticsCompany,
      //     logisticsCode: element.logisticsNumber,
      //   });
      // });
      // arr = JSON.stringify(arr);
      this.$ajax({
        url: "/hxclass-management/certifData/importCorrectLogisticsData",
        method: "POST",
        params: this.successList,
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$message.success(res.message);
          this.importVisible = false;
          this.getDataList();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    modelhandleCancel() {
      this.importVisible = false;
    },
    // 下载错误excel 借用方法  关闭弹窗
    downloadExcel() {
      this.importVisible = false;
    },

    // 文件上传更新进度和单文件上传初始化
    fileProgressCallback(progress, speed, name) {
      /*
       * progress 进度
       * speed 传输速度
       * name 文件名称
       * */
      this.progress = progress
      this.speed = speed
      
    },

    // 下载用户证书模板
    downExcleData(){
      window.open(
         this.$config.target + "/hxclass-management/certifData/excelData?status=1&tempId=" + 
          encodeURIComponent(this.queryParams.tempId) + 
          "&name=" +
          encodeURIComponent(this.queryParams.name) +
          "&mobile=" +
          encodeURIComponent(this.queryParams.mobile) +
          "&startTime=" +
          encodeURIComponent(this.queryParams.startTime) + 
          "&endTime=" +
          encodeURIComponent(this.queryParams.endTime) + 
          "&logisticsStatus=" +
          encodeURIComponent((this.queryParams.logisticsStatus == 1 || this.queryParams.logisticsStatus == 0) ? this.queryParams.logisticsStatus : '') + 
          "&startDate=" +
          encodeURIComponent(this.queryParams.startDate) + 
          "&endDate=" +
          encodeURIComponent(this.queryParams.endDate)
      );
    },

    // 选择时间
    onSelectTime(date, dateString) {
      this.queryParams.startTime = dateString[0]; // 开始日期
      this.queryParams.endTime = dateString[1]; // 结束日期
      // this.fetchExamIDSWithTime(dateString[0], dateString[1])
    },

    // 选择考试时间
    onSelectExamTime(date, dateString) {
      this.queryParams.startDate = dateString[0]; // 考试开始日期
      this.queryParams.endDate = dateString[1]; // 考试结束日期
    },

    // 根据日期时间段，请求考试场次数据
    fetchExamIDSWithTime(sTime, eTime) {
      // console("asdasdasdasdasd")
      if (sTime == "" || eTime == "") {
        this.queryParams.timeIds = ""
        return
      }
      this.loading = true
      this.$ajax({
        method: 'get',
        url:"/hxclass-management/certifData/getTimeListByCourseId",
        params: {
          courseId: this.courseId,
          startDateTime: sTime,
          endDateTime: eTime,
        },
      }).then(res => {
        this.loading = false
        var checkedValues = []
        if (res.data.length > 0) {
          for(let i=0;i<res.data.length;i++){
          let item = res.data[i]
          checkedValues.push(item.timeId)
          }
          this.queryParams.timeIds = checkedValues.join(",")
        } else {
          this.queryParams.timeIds = ""
        }
        this.getDataList()
      }).catch(error => {
        this.loading = false
        if (error) {
          this.$message.warning('系统错误');
        }
      })
    },

    // 证书作废
    deleteData(dataId) {
      let _this = this;
      this.$confirm({
        title: "确认将该证书作废吗?",
        okText: "确认",
        onOk() {
          _this
            .$ajax({
              url: "/hxclass-management/certifData/nullifyData/" + dataId,
              method: "put",
            })
            .then((res) => {
              if (res.code == 200 && res.success) {
                _this.$message.success("作废成功");
                _this.getDataList();
              } else {
                _this.$message.error(res.message);
              }
            });
        },
        onCancel() {},
      });
    },

    // 上传物流信息
    uploadLogi(e) {
      // console("adasdasdasd" + e.logisticsCode)
      this.logiObj.logisticsCode = e.logisticsCode
      this.logiObj.logisticsCompany = e.logisticsCompany
      this.logiObj.dataId = e.dataId
      this.EditVisible = true
    },
    // 编辑物流信息
    edititem() {
      this.loading = true
      this.$ajax({
        url: "/hxclass-management/certifData/uploadLogisticsInformation",
        method: "put",
        params: this.logiObj,
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.loading = false
          this.$message.success("发货成功");
          this.getDataList();
          this.EditVisible = false;
        } else {
          this.loading = false
          this.$message.error("发货失败");
        }
      });
    },

    search(){
      if(!this.queryParams.tempId){
        return this.$message.error('暂无可查数据');
      }
      this.queryParams.pageNum = 1
      this.getDataList()
    },

    // 批量审核存放报名id
    select(selectedRowKeys) {
    },

  },
  // 生命周期-实例创建完成后调用
  created () { 
    this.getCertificateList()
  },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.list-box{
  display: flex;
  margin-top: 20px;
  .certificate-list{
    width: 300px;
    min-width: 300px;
    border: 1px solid #eeeeee;
    .certificate-item{
      padding: 14px 30px;
      border-bottom: 1px solid #eeeeee;
      color: #333;
      display: flex;
      align-items: center;
      cursor: pointer;
      >span{
        line-height: 22px;
      }
      &:hover{
        >span{
          color: @theme;
        }
      }
      .hover-span{
        color: @theme;
        &::after{
          content: '';
          display: inline-block;
          margin-left: 5px;
          width: 0px;
          height: 0px;
          border: 6px solid @theme; //白色
          border-top-color: transparent;
          border-bottom-color: transparent;
          border-left-color: @theme;
          border-right-color: transparent;
        }
      }
    }
  }
  .table-box{
    margin-left: 20px;
    flex: 1;
    .course-name{
      font-size: 20px;
      font-weight: 600;
    }
    .flex-div{
      margin-top: 20px;
      display: flex;
      justify-content: right;
      flex-wrap: wrap;
      .input{
        width: 200px;
        margin-left: 30px;
        margin-bottom: 20px;
      }
      .timeInput{
        width: 230px;
        margin-left: 30px;
        margin-bottom: 20px;
      }
      .select{
        width: 140px;
        margin-left: 30px;
        margin-bottom: 20px;
      }
      .btn{
        margin: 0 15px;
      }
    }
  }
}

.all_content_box {
  .line-item {
    margin-bottom: 20px;
    display: flex;
    .all_left_name {
      width: 90px;
      text-align: right;
      margin-right: 20px;
    }
  }
  .right {
    flex: 1;
    height: 40px;
    /deep/.ant-select-selection--single {
      height: 40px;
    }
    /deep/.ant-select-selection--single .ant-select-selection__rendered {
      height: 40px;
    }
    /deep/.ant-input {
      height: 40px;
    }
  }
  textarea.ant-input {
    flex: 1;
  }
}
</style>
